import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {
    GET_COMPARISON_RESULTS_BY_PROJECT,
    OVERRIDE_AUDITOR_ANSWER_MUTATION
} from "queries/comparisons";
import {Alert} from "constants/swal";
import React, {ChangeEvent, useEffect, useState} from "react";
import {RouteComponentProps} from 'react-router-dom';
import {ComparisonInterface, ProductInterface} from "components/helpers/interfaces";
import LocalHeader from "components/base/HeaderBar/LocalHeader/LocalHeader";
import SearchBox from "components/base/SearchBox/SearchBox";
import './ProjectResults.css';
import EPackButton from "components/base/EPackButton/EPackButton";
import EpackLoader from 'components/loaders/Loaders';
import EPackSelect from "components/base/EPackSelect/EPackSelect";
import prepare_options_waves from "components/helpers/formOptionsWaves";
import {Typeahead, ClearButton} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {faAngleDoubleDown, faCog, faSearch, faFilter} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InputGroup} from "react-bootstrap";
import {WAVE_CHOICES_BY_PROJECT_ID_QUERY} from "queries/waves";
import Form from "react-bootstrap/Form";
import {
    PROJECT_RESULTS_PACKSHOT_PARSED_IMAGES_CHOICES_QUERY
} from "queries/projects";
import Card from "react-bootstrap/Card";
import {
    faSync,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

interface ProjectResultsInterface extends ComparisonInterface {
    product: ProductInterface
}

function ProjectResultsView(props: RouteComponentProps<any>) {
    const [searchShop, setSearchShop] = useState([])
    const [searchAuditorId, setSearchAuditorId] = useState([])
    const [searchPackshotType, setSearchPackshotType] = useState([])
    const [searchImgPosition, setSearchImgPosition] = useState([])
    const [searchProduct, setSearchProduct] = useState('')
    const [searchWaveId, setSearchWaveId] = useState('0');
    const [waveOptions, setWaveOptions] = useState({waveOpts: []});
    const [shopOptions, setShopOptions] = useState([]);
    const [auditorOptions, setAuditorOptions] = useState([]);
    const [availablePackshotTypesOptions, setAvailablePackshotTypesOptions] = useState([]);
    const [availableParsedImgPositionsOptions, setAvailableParsedImgPositionsOptions] = useState([]);

    const project_id = parseInt(props.match.params.id, 10);
    const {loading, error, data, fetchMore} = useQuery(GET_COMPARISON_RESULTS_BY_PROJECT, {
        variables: {project_id: project_id},
        fetchPolicy: 'cache-and-network',
    });
    const [getWaveChocies, {data: waveData, loading: waveLoading, error: waveDataError}] = useLazyQuery(WAVE_CHOICES_BY_PROJECT_ID_QUERY);
    const [getProjectResultsChoicesData, {data: projectResultsChoicesData, loading:projectResultsChoicesDataLoading, error: projectResultsChoicesDataError}] = useLazyQuery(PROJECT_RESULTS_PACKSHOT_PARSED_IMAGES_CHOICES_QUERY);

    useEffect(() => {
        const fetchWaveOptions = async () => {
            getWaveChocies({
                variables: {
                    projectId: project_id,
                }
            })
            if (waveDataError) {
                Alert.fire("Error has occurred:", waveDataError.message.replace("GraphQL error: ", "").trim(), "error");
            }
            if (waveData && waveData.wavesChoices) {
                let waveParsedOptions = prepare_options_waves(waveData.wavesChoices)
                setWaveOptions(waveParsedOptions)
                localStorage.setItem('searchWaveId', waveParsedOptions && waveParsedOptions.length > 0 ? waveParsedOptions[0].value : '0' );
            } else {
                localStorage.setItem('searchWaveId', '0' );
            }
            // Additionally set blank search results in local storage after component mounts.
            localStorage.setItem('searchShop', '');
            localStorage.setItem('searchAuditorId', '');
            localStorage.setItem('searchPackshotType', '');
            localStorage.setItem('searchImgPosition', '');
            localStorage.setItem('searchProduct', '');

        };
        const fetchProjectOptions = async () => {
            getProjectResultsChoicesData({
                variables:{
                    id:project_id
                }
            })
            if (projectResultsChoicesDataError) {
                Alert.fire("Error has occurred:", projectResultsChoicesDataError.message.replace("GraphQL error: ", "").trim(), "error");
            }

            if (projectResultsChoicesData && projectResultsChoicesData.project) {
                const projectResultsDataAll = projectResultsChoicesData.project.projectResultsChoices
                setAuditorOptions(projectResultsDataAll.availableAuditors.map((auditor: any) =>
                {return {label: auditor.fullName, value: auditor.id}}))
                setShopOptions(projectResultsDataAll.availableShops.map((shop: any) => {
                    return {value: shop, label: shop};
                }))
                setAvailablePackshotTypesOptions(projectResultsDataAll.availablePackshotTypes.map((packhotType: any) => {
                    return {value: packhotType, label: packhotType};
                }))
                setAvailableParsedImgPositionsOptions(projectResultsDataAll.availableParsedImgPositions.map((availableParsedImgPosition: any) => {
                    return {value: availableParsedImgPosition, label: availableParsedImgPosition};
                }))
            }
        };
        fetchWaveOptions();
        fetchProjectOptions();
    }, [waveData, getWaveChocies, waveDataError, project_id, projectResultsChoicesData, getProjectResultsChoicesData, projectResultsChoicesDataError]);

    if (loading) {
        return <EpackLoader/>
    }
    if (error) {
        Alert.fire(error.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {error.message}</div>;
    }

    if (data) {
        const comparisonsResultsToRender = data.comparisonsResults.edges
        const comparisonsResultsHasNextPage = data.comparisonsResults.pageInfo.hasNextPage
        localStorage.setItem('startCursor', data.comparisonsResults.pageInfo.startCursor);
        let comparisonsResultsToRenderMapped = comparisonsResultsToRender.map((result: {
            node: ProjectResultsInterface;
        }) => {
            return {
                id: result.node.id,
                isChanged: result.node.isChanged,
                packshotType: result.node.packshotType,
                referenceImgUrl: result.node.referenceImgUrl,
                parsedImgUrl: result.node.parsedImgUrl,
                referenceImg: result.node.referenceImg,
                parsedImg: result.node.parsedImg,
                parsedImgPosition: result.node.parsedImgPosition,
                algorithmAnswer: result.node.algorithmAnswer,
                rejectionReason: result.node.rejectionReason,
                auditorAnswer: result.node.auditorAnswer != null ? result.node.auditorAnswer : "Not checked by auditor",
                auditor: result.node.auditor ? result.node.auditor : {"name": "Not checked by auditor"},
                product: result.node.product,
            };
        });

        const onEnter = (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                fetchMore({
                    variables: {
                        search_auditor_id: searchAuditorId.length > 0 ? searchAuditorId[0] : "",
                        search_shop: searchShop.length > 0 ? searchShop[0] : "",
                        search_packshot_type: searchPackshotType.length > 0 ? searchPackshotType[0] : '',
                        search_image_position: searchImgPosition.length > 0 ? searchImgPosition[0] : '',
                        search_product: searchProduct,
                        search_wave_id: searchWaveId,
                    },
                    updateQuery: (prevResult, {fetchMoreResult}) => {
                        // @ts-ignore
                        fetchMoreResult.comparisonsResults.edges = [
                            // @ts-ignore
                            ...fetchMoreResult.comparisonsResults.edges,
                        ];
                        return fetchMoreResult;
                    },
                });
                // Store search inputs in local storage.
                // @ts-ignore
                localStorage.setItem('searchShop', searchShop);
                // @ts-ignore
                localStorage.setItem('searchAuditorId', searchAuditorId);
                // @ts-ignore
                localStorage.setItem('searchPackshotType', searchPackshotType);
                // @ts-ignore
                localStorage.setItem('searchImgPosition', searchImgPosition);
                localStorage.setItem('searchProduct', searchProduct);
                localStorage.setItem('searchWaveId', searchWaveId);
            }
        }
        const onFilterButtonClick = (options: any) => {
            fetchMore({
                variables: {
                        search_auditor_id: searchAuditorId.length > 0 ? searchAuditorId[0] : "",
                        search_shop: searchShop.length > 0 ? searchShop[0] : "",
                        search_packshot_type: searchPackshotType.length > 0 ? searchPackshotType[0] : '',
                        search_image_position: searchImgPosition.length > 0 ? searchImgPosition[0] : '',
                        search_product: searchProduct,
                        search_wave_id: searchWaveId,
                },
                updateQuery: (prevResult, {fetchMoreResult}) => {
                    // @ts-ignore
                    fetchMoreResult.comparisonsResults.edges = [
                        // @ts-ignore
                        ...fetchMoreResult.comparisonsResults.edges,
                    ];
                    return fetchMoreResult;
                },
            });
          // @ts-ignore
          localStorage.setItem('searchShop', searchShop);
          // @ts-ignore
          localStorage.setItem('searchAuditorId', searchAuditorId);
          // @ts-ignore
          localStorage.setItem('searchPackshotType', searchPackshotType);
          // @ts-ignore
          localStorage.setItem('searchImgPosition', searchImgPosition);
          localStorage.setItem('searchProduct', searchProduct);
          localStorage.setItem('searchWaveId', searchWaveId);
        }
        const onChangeTypeHead = (options: any) => {
            const selectedShop = options.selectedShop ? options.selectedShop[0] : localStorage.getItem('searchShop');
            const selectedAuditorId = options.selectedAuditorId ? options.selectedAuditorId[0] : localStorage.getItem('searchAuditorId');
            const selectedPackshotType = options.selectedPackshotType ? options.selectedPackshotType[0] : localStorage.getItem('searchPackshotType');
            const selectedImgPosition = options.selectedImgPosition ? options.selectedImgPosition[0] : localStorage.getItem('searchImgPosition');
            const selectedProduct = options.selectedProduct ? options.selectedProduct : localStorage.getItem('searchProduct');
            const selectedWaveId = options.selectedWaveId ? options.selectedWaveId : localStorage.getItem('searchWaveId');
            fetchMore({
                variables: {
                    search_shop: selectedShop ? selectedShop : '',
                    search_auditor_id: selectedAuditorId ? selectedAuditorId : '',
                    search_packshot_type: selectedPackshotType ? selectedPackshotType : '',
                    search_image_position: selectedImgPosition ? selectedImgPosition : '',
                    search_product: selectedProduct,
                    search_wave_id: selectedWaveId,
                },
                updateQuery: (prevResult, {fetchMoreResult}) => {
                    // @ts-ignore
                    fetchMoreResult.comparisonsResults.edges = [
                        // @ts-ignore
                        ...fetchMoreResult.comparisonsResults.edges,
                    ];
                    return fetchMoreResult;
                },
            });
            // Store search inputs in local storage.
            localStorage.setItem('searchShop', selectedShop ? selectedShop : '');
            localStorage.setItem('searchAuditorId', selectedAuditorId ? selectedAuditorId : '');
            localStorage.setItem('searchPackshotType', selectedPackshotType ? selectedPackshotType : '');
            localStorage.setItem('searchImgPosition', selectedImgPosition ? selectedImgPosition : '');
            localStorage.setItem('searchProduct', selectedProduct);
            localStorage.setItem('searchWaveId', selectedWaveId);
        }

        return (
            <div className="project-results-wrapper">
                <LocalHeader>
                    <div className="header-wrapper">
                        {(waveLoading || projectResultsChoicesDataLoading) ?
                            <div className="project-results-spiner-text"><FontAwesomeIcon icon={faSync}
                                                                                          className="project-results-spinner"/>Loading
                                filters. For larger projects it may take several seconds...</div> :
                            <div className="project-results-search-box-wrapper">
                                <Form.Group>
                                    <EPackSelect
                                        label="Enter wave id"
                                        onChangeInput={(e: ChangeEvent<HTMLInputElement>) => {
                                            setSearchWaveId(e.target.value)
                                            // onChangeTypeHead({selectedWaveId: e.target.value})

                                        }}
                                        value={searchWaveId}
                                        onEnter={onEnter}
                                        selectOptions={waveOptions}
                                        className="wider-search-box-input"
                                    />
                                  <InputGroup>
                                    <Typeahead
                                      selected={searchAuditorId}
                                      onChange={(selected: any) => {
                                        // @ts-ignore
                                        if (selected.length > 0) {
                                          // @ts-ignore
                                          setSearchAuditorId([selected[0].value])
                                          // onChangeTypeHead({selectedAuditorId: [selected[0].value]})
                                        } else {
                                          if (searchAuditorId) {
                                            setSearchAuditorId([])
                                            // Set to blank.
                                            // @ts-ignore
                                            localStorage.setItem('searchAuditorId', []);
                                            // onChangeTypeHead({selectedAuditorId: []})
                                          } else {
                                            setSearchAuditorId([])
                                          }
                                        }
                                      }}
                                      id="auditor-search-select"
                                      options={auditorOptions}
                                      labelKey="label"
                                      placeholder="Auditor">
                                      {(            // @ts-ignore
                                        {onClear, selected}) => (
                                        <div className="rbt-aux">
                                          {selected && selected != '' &&
                                            <ClearButton onClick={onClear}/>}
                                          {selected && selected == '' &&
                                            <FontAwesomeIcon icon={faSearch}
                                                             className="search-icon"/>}
                                        </div>
                                      )}
                                    </Typeahead>
                                    <Typeahead
                                      selected={searchShop}
                                      onChange={(selected: any) => {
                                        // @ts-ignore
                                        if (selected.length > 0) {
                                          // @ts-ignore
                                          setSearchShop([selected[0].value])
                                          // onChangeTypeHead({selectedShop: [selected[0].value]})
                                        } else {
                                          if (searchShop) {
                                            setSearchShop([])
                                            // Set to blank.
                                            // @ts-ignore
                                            localStorage.setItem('searchShop', []);
                                            // onChangeTypeHead({selectedShop: []})
                                          } else {
                                            setSearchShop([])
                                          }
                                        }
                                      }}
                                      id="shop-search-select"
                                      options={shopOptions}
                                      labelKey="value"
                                      placeholder="Shop name">
                                      {(            // @ts-ignore
                                        {onClear, selected}) => (
                                        <div className="rbt-aux">
                                          {selected && selected != '' &&
                                            <ClearButton onClick={onClear}/>}
                                          {selected && selected == '' &&
                                            <FontAwesomeIcon icon={faSearch}
                                                             className="search-icon"/>}
                                        </div>
                                      )}
                                    </Typeahead>
                                    <Typeahead
                                      selected={searchPackshotType}
                                      onChange={(selected: any) => {
                                        // @ts-ignore
                                        if (selected.length > 0) {
                                          // @ts-ignore
                                          setSearchPackshotType([selected[0].value])
                                          // onChangeTypeHead({selectedPackshotType: [selected[0].value]})
                                        } else {
                                          setSearchPackshotType([])
                                          // Set to blank.
                                          // @ts-ignore
                                          localStorage.setItem('searchPackshotType', []);
                                          // onChangeTypeHead({selectedPackshotType: []})
                                        }
                                      }}
                                      id="packshot-type-search-select"
                                      options={availablePackshotTypesOptions}
                                      labelKey="label"
                                      placeholder="Packshot type">
                                      {(            // @ts-ignore
                                        {onClear, selected}) => (
                                        <div className="rbt-aux">
                                          {selected && selected != '' &&
                                            <ClearButton onClick={onClear}/>}
                                          {selected && selected == '' &&
                                            <FontAwesomeIcon icon={faSearch}
                                                             className="search-icon"/>}
                                        </div>
                                      )}
                                    </Typeahead>
                                    <Typeahead
                                      selected={searchImgPosition}
                                      onChange={(selected: any) => {
                                        // @ts-ignore
                                        if (selected.length > 0) {
                                          // @ts-ignore
                                          setSearchImgPosition([selected[0].value])
                                          // onChangeTypeHead({selectedImgPosition: [selected[0].value]})
                                        } else {
                                          setSearchImgPosition([])
                                          // Set to blank.
                                          // @ts-ignore
                                          localStorage.setItem('searchImgPosition', []);
                                          // onChangeTypeHead({selectedImgPosition: []})
                                        }
                                      }}
                                      id="img-position-search-select"
                                      options={availableParsedImgPositionsOptions}
                                      labelKey="label"
                                      placeholder="Position">
                                      {(            // @ts-ignore
                                        {onClear, selected}) => (
                                        <div className="rbt-aux">
                                          {selected && selected != '' &&
                                            <ClearButton onClick={onClear}/>}
                                          {selected && selected == '' &&
                                            <FontAwesomeIcon icon={faSearch}
                                                             className="search-icon"/>}
                                        </div>
                                      )}
                                    </Typeahead>
                                    <SearchBox
                                      label="Product name or code"
                                      value={searchProduct}
                                      onChangeInput={(e) => setSearchProduct(e.target.value)}
                                      onEnter={onEnter}
                                      className="wider-search-box-input"
                                    />
                                    {/*<span className="manager-answer-buttons-wrapper">*/}
                                      <EPackButton size="sm" label="Filter" variant="primary" icon={faFilter}
                                                   onClick={onFilterButtonClick}

                                      />
                                  </InputGroup>
                                </Form.Group>
                            </div>
                        }
                      {/*<div>{waveLoading && (<EpackLoader loaderType="ballcliprotate"/>)}</div>*/}
                        {/*<div>{projectResultsChoicesDataLoading && (<EpackLoader loaderType="ballcliprotate"/>)}</div>*/}
                    </div>
                </LocalHeader>
                {comparisonsResultsToRenderMapped.length > 0  ? <React.Fragment>
                    <ProjectResultsBody projectResults={comparisonsResultsToRenderMapped}/>
                <span className="d-flex justify-content-center">
                    <span className="pt-5 mb-3">
                            {comparisonsResultsHasNextPage ?
                            <EPackButton label="Load more" variant="primary" icon={faAngleDoubleDown}
                                onClick={() => {
                                    const endCursor = data.comparisonsResults.pageInfo.endCursor;
                                    localStorage.setItem('startCursor', data.comparisonsResults.pageInfo.startCursor);
                                    fetchMore({
                                        variables: {
                                            after: endCursor,
                                            search_shop: localStorage.getItem('searchShop') || '',
                                            search_auditor_id: localStorage.getItem('searchAuditorId') || '',
                                            search_packshot_type: localStorage.getItem('searchPackshotType') || '',
                                            search_image_position: localStorage.getItem('searchImgPosition') || '',
                                            search_product: localStorage.getItem('searchProduct') || '',
                                            search_wave_id: localStorage.getItem('searchWaveId') || '',
                                        },
                                        updateQuery: (prevResult, {fetchMoreResult}) => {
                                            if (!fetchMoreResult)
                                                return prevResult;
                                            // @ts-ignore
                                            fetchMoreResult.comparisonsResults.edges = [
                                                // @ts-ignore
                                                ...prevResult.comparisonsResults.edges,
                                                // @ts-ignore
                                                ...fetchMoreResult.comparisonsResults.edges,
                                            ]
                                            window.scrollTo(0, document.documentElement.scrollHeight);
                                            return fetchMoreResult;
                                        },
                                    });
                                }}
                        /> : <></>}
                    </span>
                </span> </React.Fragment> : <div className="main-container"><Card className="text-center">
                    <Card.Body className="h5">There are no comparisons to show.</Card.Body>
                </Card></div>}
            </div>
        )
    }
    return <noscript/>
}

interface ProjectResultsProps {
    projectResults: Array<ProjectResultsInterface>
    setSearch?: any
}


function ProjectResultsBody({projectResults}: ProjectResultsProps) {
    const [
        overrideAuditorAnswer, {error: mutationError, loading: mutationLoading}
    ] = useMutation(OVERRIDE_AUDITOR_ANSWER_MUTATION, {errorPolicy: 'all'});

    if (mutationLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (mutationError) {
        // Alert user about errors.
        Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
        // TODO For production environment error codes could be added (user friendly).
        return <div>{mutationError && <p>Error : <b>{mutationError.message}</b></p>}</div>;
    }

    const handleOverrideBtnClick = (comparisonID: string, managerAnswer: boolean) => {
        overrideAuditorAnswer({
            variables: {
                comparisonId: comparisonID,
                comparisonAnswer: managerAnswer
            }
        })
            .then((data) => {
                // TODO Reload with filtered params
                // window.location.reload()
            })
            .catch(mutationError => {
                // Catch Promise.
                Alert.fire("Error has occurred:", mutationError.message.replace("GraphQL error: ", "").trim(), "error");
            });
    }

    const rows = []
    for (let result of projectResults) {
        rows.push(<ResultRow key={result.id} result={result} onOverride={handleOverrideBtnClick}/>)
    }

    return <>{rows}</>
}

interface ResultRowInterface {
    result: ProjectResultsInterface,
    onOverride: any
    key: string
}

function ResultRow({result, onOverride}: ResultRowInterface) {
    // only auditorAnswer uses state because its the only changeable value // overrideAuditorAnswerMutation
    const [auditorAnswer, setAuditorAnswer] = useState(result.auditorAnswer);

    const renderReferenceImageTooltip = (props:any) => (
    <Tooltip id="button-tooltip" {...props}>
      Reference image
    </Tooltip>
  );
        const renderParsedImageTooltip = (props:any) => (
    <Tooltip id="button-tooltip" {...props}>
      Parsed image
    </Tooltip>
  );

    return (
        <>
            <div className="project-results-body">
                <div className="manager-answer-buttons-wrapper">
                    <EPackButton
                        label="False"
                        variant="danger"
                        onClick={() => {
                            onOverride(result.id, false)

                            // TODO: rework. setAuditorAnswer cannot work outside useEffect
                            setAuditorAnswer(false)
                        }}
                    />
                    <hr/>
                    <EPackButton
                        label="True"
                        variant="success"
                        onClick={() => {
                            onOverride(result.id, true)
                            setAuditorAnswer(true)
                        }}
                    />

                </div>
                <div className="comparison-info-wrapper">
                    <div>ID: <a href={window.location.protocol + "//" +  window.location.hostname + "/admin/comparisons/comparison/" + result.id + "/change/"} target="_blank">{result.id}</a></div>
                    <div>Product Name: {result.product.name}</div>
                    <div>Product Code: {result.product.code}</div>
                    <div>Shop: {result.product.shop.name}</div>
                    <div>Packshot type: {result.packshotType}</div>
                    <div>Parsed image position: {result.parsedImgPosition}</div>
                    {/* negated isChanged. if changed cannot be from previous wave */}
                    <div>Copied from previous wave: {(!result.isChanged).toString()}</div>
                    <div>Algorithm answer: {result.algorithmAnswer == 'True' ? <span className='project-results-algorithm-answer-true'>{result.algorithmAnswer}</span>: [(result.algorithmAnswer == 'False' ? <span className='project-results-algorithm-answer-false'>{result.algorithmAnswer}</span>:<span className='project-results-algorithm-answer-skipped'>{result.algorithmAnswer}</span>)]}</div>
                    {result.algorithmAnswer == 'Skipped' ? <div>Rejection reason: <ins>{result.rejectionReason}</ins></div> : <></>}
                    {/* @ts-ignore */}
                    <div>Auditor answer: {auditorAnswer.toString() == 'true' ? <span className='project-results-algorithm-answer-true'>True</span> : [(auditorAnswer.toString() == 'false' ? <span className='project-results-algorithm-answer-false'>False</span>: auditorAnswer.toString())] }</div>
                    <div>Auditor: {result.auditor.name}</div>
                </div>
                <div className="comparison-images-wrapper">
                  <OverlayTrigger
                    placement="top"
                    delay={{show: 1, hide: 400}}
                    overlay={renderReferenceImageTooltip}
                  >
                    <img src={localStorage.getItem("epackMediaHostUrl") + result.referenceImg}
                         alt=""/>
                  </OverlayTrigger>
                                    <OverlayTrigger
                    placement="top"
                    delay={{show: 1, hide: 400}}
                    overlay={renderParsedImageTooltip}
                  >
                  <img src={localStorage.getItem("epackMediaHostUrl") + result.parsedImg}
                       alt=""/></OverlayTrigger>
                </div>
                <hr/>
            </div>
        </>
    )
}

export default ProjectResultsView;
