import { gql } from 'apollo-boost';

// Query to get comparisons by project id and shop list.
export const COMPARISON_GET_BY_PROJECT_ID_QUERY = gql`
    query GetComparisonData($projectId: Int!, $shops: [String] = []) {
        packshotTypes(projectId: $projectId, shops: $shops) {
            value
            label
        }
        auditors(projectId: $projectId, shops: $shops) {
            value
            label
        }
        auditorsInvolved(projectId: $projectId, shops: $shops) {
            value
            label
        }
    }
`;

export const COMPARISON_GET_BY_USER = gql`
    query getComparisonByUser($first: Int = 1, $after: String) {
        taskInProgress(first: $first, after: $after) {
            edges {
                node {
                    id
                    customName
                    shops {
                        name
                    }
                    comparisonsAssigned {
                        id
                        referenceImgUrl
                        referenceImg
                        progressPercentage
                        code
                        lastComparisonId
                        parsedImgComparisons {
                            id
                            parsedImgUrl
                            parsedImg
                        }
                    }
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const SET_COMPARISON_AUDITOR_ANSWER_MUTATION = gql`
    mutation SetComparisonAuditorAnswerMutation(
        $comparisonId: ID!
        $answerComparisonList: [ID]
        $taskId: ID
    ) {
        setComparisonAuditorAnswer(
            comparisonId: $comparisonId
            answerComparison: $answerComparisonList
            taskId: $taskId
        ) {
            comparison {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const UNDO_LAST_AUDITOR_ANSWER_MUTATION = gql`
    mutation UndoLastAuditorAnswerMutation($comparisonId: ID!) {
        undoLastComparisonAuditorAnswer(comparisonId: $comparisonId) {
            comparison {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const GET_COMPARISON_RESULTS_BY_PROJECT = gql`
    query getComparisonByProject(
        $project_id: ID!
        $first: Int = 100
        $after: String
        $search_shop: String
        $search_wave_id: Int
        $search_packshot_type: String
        $search_image_position: String
        $search_product: String
        $search_auditor_id: String
        $order: [String]
    ) {
        comparisonsResults(
            projectId: $project_id
            first: $first
            after: $after
            searchShop: $search_shop
            searchWaveId: $search_wave_id
            searchPackshotType: $search_packshot_type
            searchImagePosition: $search_image_position
            searchProduct: $search_product
            searchAuditorId: $search_auditor_id
            order: $order
        ) {
            edges {
                cursor
                node {
                    id
                    product {
                        id
                        name
                        code
                        shop {
                            id
                            name
                        }
                    }
                    isChanged
                    packshotType
                    parsedImgPosition
                    algorithmAnswer
                    auditorAnswer
                    rejectionReason
                    auditor {
                        name
                    }
                    referenceImgUrl
                    referenceImg
                    parsedImgUrl
                    parsedImg
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const OVERRIDE_AUDITOR_ANSWER_MUTATION = gql`
    mutation OverrideComparisonAuditorAnswerMutation($comparisonId: ID!, $comparisonAnswer: Boolean) {
        overrideAuditorAnswer(comparisonId: $comparisonId, comparisonAnswer: $comparisonAnswer) {
            comparison {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;
